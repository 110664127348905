import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {withRouter} from "../../util/router"
import { connect } from "react-redux";
import {canLoginAPI, cloudLoginAPI} from "../../actions/userAuthenticate"
import _ from "lodash";
import GoogleLoginIcon from "./../../assets/images/google_login.png"
import "./googleLogin.css"

const GoogleLogin = (props:any)=>{

  const {authReducer, canLoginAPI, cloudLoginAPI} = props

    const handleCloudLogin = ()=>{
        let payload = {
            workspaceId: authReducer.workspaceId
        }
        cloudLoginAPI(payload)
    }

    return (
        <>
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <p className='googleSigninHeading'>Sign in to your account</p>
                <Button
                    component="label"
                    startIcon={<Avatar  src={GoogleLoginIcon}/>}
                    variant="outlined"
                    onClick={handleCloudLogin}
                    >
                    Sign in using Google Account
                </Button>
            </Box>
        </Container>
        </>
    )
}

const mapStateToProps = (state:any) => ({
  //props made from redux store
  authReducer: state.authReducer,
});

export default withRouter(
  connect(mapStateToProps, {
      //All functions defined in action will come here
      canLoginAPI,
      cloudLoginAPI,
  })(GoogleLogin)
);