import * as React from 'react';
import {useEffect, useState} from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {withRouter} from "../../util/router"
import { connect } from "react-redux";
import {canLoginAPI} from "../../actions/userAuthenticate"
import _ from "lodash";
import { Base64 } from "js-base64";

const LoginRedirect = (props:any)=>{

    useEffect(()=>{
      let urlSearchParams = new URLSearchParams(window.location.search)
      let accessToken:any = urlSearchParams.get('access_token');
      let userName:any = urlSearchParams.get('userName');
      let email:any = urlSearchParams.get('email');
      let refreshToken:any = urlSearchParams.get('refresh_token');
      window.location.href="kognitive://access_token="+accessToken+"&refresh_token="+refreshToken+"&userName="+userName+"&email="+email
    }, [])

    return (
        <>
            <h3>Login Successful</h3>
        </>
    )
}

const mapStateToProps = (state:any) => ({
  //props made from redux store
  authReducer: state.authReducer,
});

export default withRouter(
  connect(mapStateToProps, {
      //All functions defined in action will come here
      canLoginAPI,
  })(LoginRedirect)
);