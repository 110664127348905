import {LOGIN_USER, GET_ERROR, LOGOUT_USER, AUTH_TYPES} from "./types"
import {api} from "./../util/request"
import { SUCCESS_LOGIN } from "../constants/messages";
import { CUSTOM_TOAST_ERROR_CLASSNAME, CUSTOM_TOAST_SUCCESS_CLASSNAME, TOAST_SETTING_AUTOCLOSE, TOAST_SETTING_HIDE_PROGRESSBAR } from "../constants/constants";
import { toast } from 'react-toastify';
import { API_URL, AUTH_URL } from "../config";

export const canLoginAPI = (payload:any) => (dispatch:any) =>{
    const response = {}
    api.postRequest("/captive/auth/login", payload).then((res)=>{
        if (res && (res.success && res.status === 201 || res.status === 200 || res.status === 202)) {
            toast.success(SUCCESS_LOGIN, {
                hideProgressBar: TOAST_SETTING_HIDE_PROGRESSBAR,
                className: CUSTOM_TOAST_SUCCESS_CLASSNAME,
                position: toast.POSITION.BOTTOM_LEFT,
                pauseOnHover: true
            });
            dispatch({ type: LOGIN_USER, payload: res.data });
        } else if (res && !res.success){
            toast.error(res?.errors[0]?.message, {
                hideProgressBar: TOAST_SETTING_HIDE_PROGRESSBAR,
                className: CUSTOM_TOAST_ERROR_CLASSNAME,
                position: toast.POSITION.BOTTOM_LEFT,
                pauseOnHover: true
            });
            dispatch({ type: GET_ERROR, payload: { message: res?.errors[0]?.message } });
        }
    }).catch((err)=>{
        toast.error(err.message, {
            hideProgressBar: TOAST_SETTING_HIDE_PROGRESSBAR,
            className: CUSTOM_TOAST_ERROR_CLASSNAME,
            position: toast.POSITION.BOTTOM_LEFT,
            pauseOnHover: true
        });
        dispatch({ type: GET_ERROR, payload: { message: err.message } });
    })
}

export const logoutAPI = (payload:any) => (dispatch:any) =>{
    const response = {}
    api.postRequest("/iam/user/logout", payload).then((res)=>{
        //success logged out
        dispatch({ type: LOGOUT_USER, payload: {} });
    }).catch((err)=>{
        dispatch({ type: GET_ERROR, payload: { message: err.message } });
    })
}

export const getAuthMethods = (payload:any) => (dispatch:any) =>{
    const response = {}
    //to integrate CAN Login API
    api.getRequest("/captive/dp/"+payload.workspace+"/idp").then((res)=>{
        if (res && (res.success && res.status === 201 || res.status === 200 || res.status === 202)) {
            dispatch({ type: AUTH_TYPES, payload: {
                authType: res?.data?.data?.dp?.authType,
                workspaceId: res?.data?.data?.dp?.idpId,
                workspaceName: payload.workspace
            }});
        } else if (res && !res.success){
            toast.error(res?.errors[0]?.message, {
                hideProgressBar: TOAST_SETTING_HIDE_PROGRESSBAR,
                className: CUSTOM_TOAST_ERROR_CLASSNAME,
                position: toast.POSITION.BOTTOM_LEFT,
                pauseOnHover: true
            });
            dispatch({ type: GET_ERROR, payload: { message: res?.errors[0]?.message } });
        }
        console.log(res)
        //
    }).catch((err)=>{
        console.log(err)
    })
}


export const cloudLoginAPI = (payload:any) => (dispatch:any) =>{
    const response = {}
    fetch(API_URL+"/captive/auth/idp/login/"+payload.workspaceId+"?redirect_uri="+AUTH_URL+"/loginRedirect?access_token=<<access_token>>%26refresh_token=<<refresh_token>>%26userName=<<userName>>%26email=<<email>>", {
        redirect:"manual",
        method:"GET",
        headers:{
            "deviceId":payload.deviceId
        }
    }).then((res)=>{
        console.log(res)
        window.location.href=res.url
    }).catch((err)=>{
        dispatch({ type: GET_ERROR, payload: { message: err.message } });
    })
}