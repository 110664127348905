
import { AUTH_TYPES, LOGIN_USER, LOGOUT_USER } from '../actions/types'
const initialState = {
    loginUser:{},
    loggedIn:false,
    userProfileId:{},
    authType:"",
    workspaceName:"",
    workspaceId:""
}

export default function authReducer(state = initialState, action:any){
    switch(action.type){
        case LOGIN_USER:{
            return{
                ...state,
                loginUser: action.payload,
                loggedIn:true,
                loggedOut:false
            }
        }
        case AUTH_TYPES:{
            return {
                ...state,
                authType: action.payload.authType,
                workspaceName: action.payload.workspaceName,
                workspaceId: action.payload.workspaceId
            }
        }
        default:
            return state
    }
}