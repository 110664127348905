import {useState} from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {withRouter} from "../../util/router"
import { connect } from "react-redux";
import {canLoginAPI} from "../../actions/userAuthenticate"
import _ from "lodash";
import { Base64 } from "js-base64";
import "./login.css"

const Login = (props:any)=>{

  const {authReducer, canLoginAPI} = props

    const [userName, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    
    const handleLogin = ()=>{
      let payload = {
          "username": userName,
          "password": Base64.encode(password)
      }
      canLoginAPI(payload)
    }

    return (
        <>
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box component="form" onSubmit={()=>{}} noValidate>
                <p className='loginHeading'>Sign in to your account</p>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Username"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={userName}
                  onChange={(e)=>{setUsername(e.target.value)}}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e)=>{setPassword(e.target.value)}}
                />
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, backgroundColor:"#2F4B82"}}
                  onClick={handleLogin}
                >
                  Log In
                </Button>
              </Box>
            </Box>
          </Container>
        </>
    )
}

const mapStateToProps = (state:any) => ({
  //props made from redux store
  authReducer: state.authReducer,
});

export default withRouter(
  connect(mapStateToProps, {
      //All functions defined in action will come here
      canLoginAPI,
  })(Login)
);