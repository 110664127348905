import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import {withRouter} from "../util/router"
import { useEffect } from "react";
import Login from "./../pages/Login"
import LoginRedirect from "./../pages/LoginRedirect"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import "./toast.css"
import Workspace from "../pages/Workspace";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import RightImg from "./../../src/assets/images/right_img.png"
import KognitiveLogo from "./../../src/assets/images/kogitive_networks_logo.png"
import "./routes.css"

const RootRouter = (props:any)=>{
    const {authReducer} = props
    return (
        <main>
            <ToastContainer />
            <Box className="mainContainer">
                <img src={KognitiveLogo} className="kognitveLogo"></img>
                <Grid container sx={{alignItems:"center", height:"100vh"}}>
                    <Grid item xs={5}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                            className="mainBoxContainer"
                        >
                            <Routes>
                                <Route path='/' element={<Workspace />} />
                                <Route path='/login' element={<Login />} />
                                <Route path='/loginRedirect' element={<LoginRedirect />} />
                            </Routes>
                        </Box>
                    </Grid>
                    <Grid item xs={7}>
                        <img src={RightImg} className='imgWorkspace'/>
                    </Grid>
                </Grid>
            </Box>
        </main>
    )
}

const mapStateToProps = (state:any) => ({
    //props made from redux store
    authReducer: state.authReducer,
});
  
export default withRouter(
    connect(mapStateToProps, {
        //All functions defined in action will come here
    })(RootRouter)
);