import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {withRouter} from "../../util/router"
import { connect } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import {canLoginAPI, cloudLoginAPI} from "../../actions/userAuthenticate"
import _ from "lodash";
import AzureLoginIcon from "./../../assets/images/azure_login.png"
import "./azureLogin.css"

const AzureLogin = (props:any)=>{

    const [searchParams] = useSearchParams();
    const {authReducer, canLoginAPI, cloudLoginAPI} = props

    const handleCloudLogin = ()=>{
        let deviceId:any = searchParams.get("deviceId")
        let payload = {
            workspaceId: authReducer.workspaceId,
            deviceId: deviceId
        }
        cloudLoginAPI(payload)
    }

    return (
        <>
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <p className='azureSigninHeading'>Sign in to your account</p>
                <Button
                    component="label"
                    startIcon={<Avatar  src={AzureLoginIcon}/>}
                    variant="outlined"
                    onClick={handleCloudLogin}
                    >
                    Sign in using Azure Account
                </Button>
            </Box>
        </Container>
        </>
    )
}

const mapStateToProps = (state:any) => ({
  //props made from redux store
  authReducer: state.authReducer,
});

export default withRouter(
  connect(mapStateToProps, {
      //All functions defined in action will come here
      canLoginAPI,
      cloudLoginAPI,
  })(AzureLogin)
);