import {useEffect, useState} from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {withRouter} from "../../util/router"
import { connect } from "react-redux";
import _ from "lodash";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAuthMethods, cloudLoginAPI } from '../../actions/userAuthenticate';
import Login from '../Login';
import { useCookies }  from "react-cookie"
import AzureLogin from '../AzureLogin';
import GoogleLogin from '../GoogleLogin';
import "./workspace.css"


const Workspace = (props:any)=>{

    const {authReducer, getAuthMethods, cloudLoginAPI} = props
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const [workspace, setWorkspace] = useState<string>("")
    const [authType, setAuthType] = useState("")

    const [cookies, setCookies]= useCookies()

    useEffect(()=>{
        let workspace:any = searchParams.get("workspace") ||  cookies.workspace
        let deviceId:any = searchParams.get("deviceId")
        if(workspace != undefined || workspace != null || workspace?.length > 0){
            let payload = {
                workspace: workspace
            }
            navigate({
                pathname:"/",
                search:"?workspace="+workspace+"&deviceId="+deviceId
            })
            getAuthMethods(payload)
        }
    }, [])

    useEffect(()=>{
        if (!_.isEmpty(authReducer.authType)){
            setAuthType(authReducer.authType)
            setCookies('workspaceId', authReducer.workspaceId);
        }
    }, [authReducer.authType])

    const getWorkspaceAuthType = ()=>{
        setCookies('workspace', workspace);
        let deviceId:any = searchParams.get("deviceId")
        navigate({
            pathname:"/",
            search:"?workspace="+workspace+"&deviceId="+deviceId
        })
        let payload = {
            workspace: workspace,
            deviceId: deviceId
        }
        getAuthMethods(payload)
    }

    const handleCloudLogin = ()=>{
        let payload = {
            workspaceId: authReducer.workspaceId
        }
        cloudLoginAPI(payload)
    }
    return (
        <>
            {
                authType == "" ?
                    <Container component="main" maxWidth="xs">
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent:"center",
                                alignItems:"center"
                            }}
                        >
                            <p className='workspaceHeading'>
                            Name your workspace
                            </p>
                            <p className='workspaceSubHeading'>
                            This will be your workspace web address
                            </p>
                            <Box component="form" onSubmit={()=>{}} noValidate sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="workspace"
                                    label="Enter Workspace name"
                                    name="workspace"
                                    autoComplete="workspace"
                                    autoFocus
                                    value={workspace}
                                    onChange={(e)=>{setWorkspace(e.target.value)}}
                                />
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2, backgroundColor:"#2F4B82"}}
                                    onClick={getWorkspaceAuthType}
                                >
                                    Confirm Workspace
                                </Button>
                            </Box>
                        </Box>
                    </Container>
                : authType == "Basic" ?
                <>
                    <Login></Login>
                </>
                : authType == "azure"?
                <>
                    <AzureLogin></AzureLogin>
                </>
                : authType == "google" ? 
                <>
                    <GoogleLogin></GoogleLogin>
                </>
                :<></>
            }   
        </>
    )
}

const mapStateToProps = (state:any) => ({
  //props made from redux store
  authReducer: state.authReducer,
});

export default withRouter(
  connect(mapStateToProps, {
      //All functions defined in action will come here
      getAuthMethods,
      cloudLoginAPI,
  })(Workspace)
);