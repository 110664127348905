import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers";

const initialState = {};

const composedEnhancer = compose(applyMiddleware(thunk))

const store = createStore(
    rootReducer,
    initialState,
    composedEnhancer
);

export default store;