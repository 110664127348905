import axios from "axios";
import {API_URL} from "./../config"

const postRequest = async (url:any, data = {}, headers = {}) => {
    try {
        const res = await axios({
        url: API_URL + url,
        method: "POST",
        headers: Object.assign({}, headers),
        data,
        });
        return res && res.data && res.data.result ? res.data.result : res;
    } catch (err:any) {
        return err && err.response && err.response.data ? err.response.data : err;
    }
};

const getRequest = async (url:any, headers = {}) => {
    try {
        const res = await axios({
        url: API_URL + url,
        method: "GET",
        headers: Object.assign({}, headers),
        });
        return res && res.data && res.data.result ? res.data.result : res;
    } catch (err:any) {
        return err && err.response && err.response.data ? err.response.data : err;
    }
};

export const api = {
    postRequest,
    getRequest
}